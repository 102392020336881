import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg version="1.0"
         xmlns="http://www.w3.org/2000/svg"
         className={classes.svgHover}
         viewBox="0 0 400.000000 400.000000"
         preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
fill="#f590a9" stroke="none">
<path d="M1010 3494 c-219 -32 -362 -84 -515 -187 -86 -59 -225 -196 -283
-280 -285 -413 -266 -953 48 -1339 32 -40 416 -429 852 -865 555 -555 804
-797 830 -808 50 -20 93 -19 142 5 28 13 305 284 852 832 446 447 824 831 841
853 297 390 309 918 31 1322 -58 84 -197 221 -283 280 -326 221 -748 253
-1100 84 -122 -59 -212 -123 -327 -231 l-88 -82 -87 82 c-207 194 -415 298
-663 330 -72 9 -198 11 -250 4z m286 -360 c77 -16 186 -64 262 -115 26 -18
117 -101 202 -185 86 -84 168 -158 184 -163 35 -14 97 -14 132 0 16 5 98 79
184 163 162 159 203 192 309 245 137 69 334 92 501 57 266 -56 501 -283 571
-553 27 -107 28 -279 0 -384 -23 -89 -77 -201 -131 -273 -21 -28 -367 -379
-769 -781 l-731 -730 -731 730 c-402 402 -748 753 -769 781 -102 136 -151 286
-151 464 0 135 21 231 74 336 76 151 190 267 336 343 161 83 337 105 527 65z"/>
</g>
</svg>
    );
};
